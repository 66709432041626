.sell-collected-nft h2.sell-collected-nft-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    color: #FFFFFF;
    letter-spacing: 1px;
}

.sell-collected-nft select.filter-select {
    width: 165px;
    height: 44px;
    border: 1px solid #5E5E5E;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    background-color: #212529;
    /* border-left: none; */
    text-align: left;
    background-position-x: 90%;
    background-position-y: 17px;
    padding: 0px 10px;
}

.d-flex.bid-amount-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
}



button.back-button {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    background-color: transparent;
    border: none;
}

.sell-collected-nft .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    text-align: center;
    font-family: arial;
    margin-top: 30px;
    align-items: stretch !important;
    padding: 0px;
    background-color: #212529;
    border: 1px solid #5E5E5E;
    float: left;
    margin: 23px;
    cursor: pointer;
    width: 100%;
}

.sell-collected-nft h4.fees-heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
   
}

.sell-collected-nft h4.service-heading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    margin-top: 20px;
}

.sell-collected-nft button.btn.btn-primary.sell-collected {
    background: rgba(63, 81, 89, 0.2);
    border: 1px solid #5E5E5E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 10px;
}

.sell-collected-nft .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 100%;
    text-align: center;
    font-family: arial;
    margin-top: 30px;
    align-items: stretch !important;
    padding: 0px;
    background-color: #212529;
    border: 1px solid #5E5E5E;
    float: left;
    margin: 23px;
    cursor: pointer;
    width: 100%;
    height: 500px;
}



.sell-collected-nft .card img {
    height: max-content;
}

.sell-collected-nft .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #212529;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgb(255 255 255 / 25%);
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;

    color: #FFFFFF;
}

select.select-currency {
    background: #24282A;
    border: 1px solid #5E5E5E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    color: white;
    width: 165px;
    height: 44px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABbSURBVHgBlZDRDcAgCEQh6QDdkI7gCI7STthucMWoUYmSeAkfHO8ggWhXAC5kicOI1qsVDu3P4t9qEDM/Fk6z0n7VjGgSs7kq2rNDyIUXIR9ehHy4C6XPhdnsB6uXhpZEvH0hAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: 20px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    text-align: left;
    /* width: 165px; */
    /* height: 51px; */
    padding: 6px 12px;
}