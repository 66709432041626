.profile-tab-section ul.dropdown-menu.show {
    background: #3D4447;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 30.1471px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 4.5288px 3.62304px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #000000;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: white;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.profile-tab-section input.searchInput {
    background-color: #212529;
    border: 1px solid #5e5e5e;
    color: white;
    border-radius: 10px;
    padding-left: 7px;
    width: 72%;
    padding-top: 9px;
    padding-bottom: 9px;

}

.profile-tab-section select.sort-select {
    width: 134px;
    height: 44px;
    border: 1px solid #5E5E5E;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    background-color: #212529;
    border-left: none;
    text-align: center;
}

input.searchInput:focus-visible {
    outline: none;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #000000;
    background-color: transparent;
}
.search-section label{
    cursor: pointer;
}

@media screen and (min-width: 600px) {
    .search-section {
        margin: 7px;
    }

    .profile-tab-section .card {
        max-width: max-content;
    }
}
.offerMade {
    background: #293033;
    border-radius: 10px;
    padding: 8px 4px;
    margin-top: 30px;
}