@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 150px;
    height: 150px;
    border: 15px solid #7ccfc3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }