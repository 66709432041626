.market-place-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
    margin-left: 5px;
    letter-spacing: 1px;
}

main {
    background-color: #212529;
    min-height: 90vh;
}

.market-img {
    margin-top: 28px;
    margin-right: 1px;
    width: 30px;
    height: 28px;
    align-items: center;
}

.market-place-section {
    padding-top: 54px;
    padding-bottom: 23px;
}

select.filter-select {
    width: 221px;
    height: 44px;
    border: 1px solid #5E5E5E;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    background-color: #212529;
    border-left: none;
    text-align: left;
    padding-left: 40px;
}

select.sort-select {
    width: 221px;
    height: 44px;
    border: 1px solid #5E5E5E;
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    background-color: #212529;
    border-left: none;
    padding-left: 40px;

}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #212529;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    border-right: none;
    border: 1px solid #5E5E5E;
    border-radius: 10px;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 20%;
    text-align: center;
    font-family: arial;
    margin-top: 30px;
    align-items: stretch !important;
    padding: 0px;
    background-color: #212529;
    border: 1px solid #5E5E5E;
    float: left;
    margin: 23px;
    cursor: pointer;
    padding-bottom: 5px;
}

.cart-price {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #FFFFFF;
    margin-top: 6px;
    margin-left: 5px;
}

.card img {
    height: 240px;
    width: 100%;
}

.price {
    color: grey;
    font-size: 22px;
}

.card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}



.cart-name {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: white;
    text-align: left;
    margin: 0;
    margin: 6px;
}

.cart-details {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
    text-align: left;
    margin: 0;
    margin: 6px;
}

img.eth-image {
    width: 37px;
    height: 41px;
    margin: 0px;
}

.card-section {
    margin-bottom: 5px;
    height: 368px;
    background: rgba(63, 81, 89, 0.2);
}

.card {
    height: 374px;
    border-radius: 17px;

}

label.filter {
    position: relative;
    top: 0px;
    left: 34px;
    z-index: 9;
}

label.input-group-text.sort {
    position: relative;
    left: 31px;
    z-index: 9;
    padding: 0px 14px;
}

@media (max-width: 992px) {
    .card {
        max-width: -webkit-fill-available;
    }
}

.sold-out {
    display: none;
}

.card .sold-out {
    display: block;
    position: relative;
    top: 50px;
}

.card .sold-out img {
    width: 87px;
    height: 87px;
}



.card-section:hover {
    background: rgb(72 72 72 / 48%);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.2px);
    -webkit-backdrop-filter: blur(9.2px);
    /* border: 1px solid rgba(255, 255, 255, 0.51); */
    z-index: 99;
    filter: blur(8px);

}

.cart:hover {
    filter: blur(8px);
}

.sold-out {
    pointer-events: none;
    
}

.card .sold-out {
    display: block;
    position: relative;
    top: -276px;
    z-index: 999;
}
.card-section h1, .card-section h2, .card-section p, .card-section .d-flex {
    padding-left: 5px;
}