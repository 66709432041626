.details-page {
    background: rgba(63, 81, 89, 0.2);
    border: 1px solid #5E5E5E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 10px;
    width: 100%;

}

.details-suggestion {
    background: rgba(63, 81, 89, 0.2);
    border: 1px solid #5E5E5E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    margin: 0px 0px 20px 0px;
    width: 85%;
    height: 134px;
    overflow: hidden;
}

.details-suggestion img {
    width: 100%;
    height: 133px;
    border-radius: 9px;
    /* height: 100%; */
}

img.eth-image {
    width: 37px;
    height: 41px;
    margin: 0px;
}

img.product-page-img {
    width: 100%;
    height: 483px;
    border-radius: 9px;

}

table {
    width: 100%;
}



th {

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    padding: 10px;
    color: #828282;
}

td {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 10px;
}

.product-description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
    padding: 10px;
}

.padding-start {
    padding-left: 10px;
    padding-top: 24px;
}

p.product-description-details {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 10px;
}

button.but-nft-button {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #7DCFC3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4px);
    border-radius: 28px;
    width: 120px;
    height: 50px;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #FFFFFF;
}

button.place-bid-button {
    width: 120px;
    height: 50px;
    border: 1px solid #7DCFC3;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 28px;
    background: transparent;
    color: white;
}

h2.but-sales {
    font-weight: 700;
    font-size: 35px;
    line-height: 38px;
    letter-spacing: 0.15em;
    color: #7DCFC3;
    margin-top: 14px;
    margin-left: 14px;
    margin-bottom: 20px;
}

h3.address-show {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    margin-top: 28px;

}

h3.address-show span {
    color: #7dcfc3;
    width: 100px;
    overflow: hidden;
    display: inline-block;
    width: 145px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin: -4px 4px;
}

.d-flex.views {
    margin: 5px;
    color: white;
    /* justify-content: start; */
}

.d-flex.views i {
    margin-right: 3px;
    margin-top: 2px;
    margin-left: 3px;
}

.d-flex.views p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}

@media (max-width: 992px) {
    .padding-start {
        height: 300px;
    }

    .details-page {
        height: 1100px;
        margin-bottom: 10px;
    }

    h3.address-show {
        margin-left: 10px;
        text-align: center;
    }

    .card {
        max-width: 230px;
    }
}

.padding-top30px {
    padding-top: 30px;
}

.form-check-input {
    width: 0.89em;
    height: 0.9em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgb(255 255 255 / 25%);
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}

img.eth-image {
    width: 29px;
    height: 29px !important;
    margin: 10px 3px;
}