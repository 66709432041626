.colleted-details-page .col-lg-4.product-section {
  border: 1px solid white;
  background: rgba(63, 81, 89, 0.2);
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  height: 532px;
  margin-top: 47px;
  margin-right: 20px;
}

.listing-details {
  margin-left: 18px;
}

.colleted-details-page .d-flex.views p {
  align-items: center;
  margin: 0px;
}

.colleted-details-page img.product-page-img {
  width: 100%;
  height: 510px;
  border-radius: 9px;
  margin-bottom: 10px;
}

.colleted-details-page h3.owned-status span {
  color: #7dcfc3;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

.colleted-details-page h3.owned-status {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #828282;
}

.colleted-details-page button.but-nft-button {
  margin: 7px;
}

.colleted-details-page .price-section {
  /* border: 1px solid; */
  background: rgba(63, 81, 89, 0.2);
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  height: 390px;
}

.colleted-details-page .header-section.d-flex.justify-content-between {
  border: 1px solid #5e5e5e;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  backdrop-filter: blur(40px);
  border-radius: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 9px;
}

.colleted-details-page h3.price-section-header {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin: 6px;
}

.colleted-details-page i.fa.fa-angle-up,
.colleted-details-page .fa.fa-angle-down {
  color: white;
  font-size: 27px;
}

.colleted-details-page h2.nft-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #ffffff;
  margin-top: 14px;
}

.colleted-details-page .over-view-border {
  background: rgba(63, 81, 89, 0.2);
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  margin: 10px 11px;
  /* margin-right: 5px; */
  width: 94%;
}

.colleted-details-page p.no-price {
  text-align: center;
  color: white;
  margin: 100px 0px;
}

.no-padding {
  padding: 0px !important;
  margin: 0px !important;
}

.colleted-details-page .listing-border {
  background: rgba(63, 81, 89, 0.2);
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  margin-top: 11px;
  padding: 10px;
}

.colleted-details-page p.product-description-details {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 8px;
}

.colleted-details-page .product-description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.colleted-details-page .col-lg-6.padding-start {
  margin-left: 10px;
}

.colleted-details-page .price-history-section {
  background: rgba(63, 81, 89, 0.2);
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  margin-top: 21px;
  margin-bottom: 10px;
}

.colleted-details-page th,
.colleted-details-page td {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  padding: 10px;
  color: #ffffff;
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.colleted-details-page button.view-colletion {
  background: rgba(63, 81, 89, 0.2);
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  margin-bottom: 100px;
  color: white;
  padding: 5px;
  margin-top: 20px;
}

.colleted-details-page .price-history,
.colleted-details-page .accordion-button {
  background-color: #2b343a;
}

.colleted-details-page .price-history .accordion-button {
  height: 50px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border: none;
}

.colleted-details-page .price-history div#flush-collapseOne,
.colleted-details-page .price-history button.accordion-button {
  background-color: #262e33;
  color: white;
  border: none;
}

.colleted-details-page .price-history .accordion-item {
  background-color: #262e33;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.colleted-details-page .price-history {
  background-color: #2b343a;
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 10px;
}

.colleted-details-page .price-history .accordion-item {
  background-color: #262e33;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
}

.colleted-details-page .price-history button.accordion-button {
  border-radius: 10px;
}

.colleted-details-page button.accordion-button.collapsed,
.colleted-details-page button.accordion-button {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  padding: 9px;
}

.colleted-details-page button.accordion-button {}

h2#flush-headingofferone {
  background-color: #2b343a;
  border-radius: 10px;
  margin: 0px;
}

.accordion-flush .offer-price button.accordion-button.collapsed {
  color: white;
}

h2#flush-headingOne {
  background-color: #212529;
  border-radius: 10px;
  margin: 0px;
}

div#flush-collapseofferone .accordion-body {
  background-color: #212529;
}

.offer-price {
  border-radius: 10px;
  border: 1px solid #5e5e5e;
  padding: 0px;
  width: 94%;
  margin-left: 11px;
}

div#accordionFlushExample .accordion-item {
  border-radius: 10px;
}

.offer-price .accordion-button.collapsed,
.offer-price .accordion-button {
  border: none;
  border-radius: 10px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border: none;
  border-radius: 10px;
  color: white;
}

div#flush-collapseofferone .accordion-body {
  border-radius: 10px;
  background-color: #212529;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color: white;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  color: white;
}

.accordion-body {
  color: white;
}

.accept-button {
  background: rgba(63, 81, 89, 0.2);
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  border-radius: 4px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #7dcfc3;
  padding: 5px 10px;
}

div#flush-collapseoffertwo .accordion-body {
  padding: 0px !important;
}

div#flush-collapseoffertwo thead {
  border: 1px solid #5e5e5e;
  border-left: none;
  border-right: none;
}

div#flush-collapseoffertwo th,
div#flush-collapseoffertwo td {
  text-align: left !important;
}

h2.accept-bid-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  text-align: center;
  margin: 29px 0px;
}

.accetp-place-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #ffffff;
}

h4.accept-own-place-subtitle span {
  color: #7dcfc3;
}

h4.accept-own-place-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11.165px;
  line-height: 15px;
  color: #828282;
}

.accept-fee-place-subtitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

h3.accetp-place-subtitle-subtotal {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  text-align: right;
}

.modal-dialog.modal-50w.accept-place-bid {
  background: #24282a;
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 10px;
}

button.btn.btn-primary.accept-place-bid {
  background: rgba(63, 81, 89, 0.2);
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 10px;
  padding: 10px 36px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #7dcfc3;
}

.transfered-status tr {
  border: 1px solid white;
  /* background: rgba(63, 81, 89, 0.2); */
  border: 1px solid #5e5e5e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  /* backdrop-filter: blur(40px); */
  /* border-radius: 4px; */
  /* margin: 10px 10px; */
}

.transfered-status th,
.transfered-status td {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  text-align: center;
}

td.transaction-address {
  color: #7dcfc3;
}

h3.transaction-accept-bid-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #828282;
  text-align: center;
}

.price-history .accordion-body {
  /* padding: 1rem 1.25rem; */
  padding: 0;
}

.price-history thead tr {
  border: 1px solid #5e5e5e;
  border-left: none;
  border-right: none;
}

.price-history thead th,
.price-history td {
  text-align: center;
}

.colleted-details-page .details-section h5 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.colleted-details-page .details-section h5.colored  {
  color: #7dcfc3;
}

.colleted-details-page .details-section p.colored {
  color: #7dcfc3;
  font-size: 17px;
  margin-top: 10px;

}
.colleted-details-page .details-section a.colored {
  color: #7dcfc3;
  font-size: 17px;
  margin-top: 10px;
  text-decoration: none;
}

.colleted-details-page .details-section h5.gray {
  color: #818586;
}

div#accordionFlushExample .accordion-item,
.accordion .accordion-flush .price-history .accordion-item {
  border-radius: 10px;
  border: 1px solid #5e5e5e;
  background-color: #2b343a;
}

.accordion-header {
  margin: 0px;
}

p.address {
  color: #b9b9b9;
  text-overflow: ellipsis;
  width: 192px;
  overflow: hidden;
  margin-bottom: 0px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.image-with-center-text-section {
  display: flex;
  align-items: center;
}

td.user-name-color {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7DCFC3;
}