.sidenav {
    height: 100%;
    width: 300px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20px;
    margin-top: 98px;
    padding: 32px 30px;
}

.sidenav a {
    padding: 6px 6px 6px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.main {
    margin-left: 300px;
    height: 100vh;
    padding: 37px 22px;
}

.sidenav a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #828282;
}

/* Active/current link */
.sidenav a.active {
    background-color: #3d4447;
    color: white;
    background: rgba(63, 81, 89, 0.2);
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

.sidenav a img {
    padding-right: 7px;
}

h3.settings-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 39px;
}

h4.setting-profile-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
    color: #FFFFFF;
}

.settings .form-label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    margin-top: 14px;
}

.settings button.btn.btn-primary {
    background: rgba(63, 81, 89, 0.2);
    border: 1px solid #5E5E5E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #7DCFC3;
}

.settings .form-control {
    background-color: black;
    background: rgba(63, 81, 89, 0.2);
    border-radius: 10px;
    border: none;
    padding: 11px 13px;
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #828282;
}

.settings form {
    margin-top: 18px;
}

.settings .profile-image {
    text-align: center;
    /* max-width: max-content; */
    background-color: gray;
    border-radius: 50%;
    width: 213px;
    height: 212px;
    margin: 60px;
}

.settings button.btn.btn-primary {
    background: rgba(63, 81, 89, 0.2);
    border: 1px solid #5E5E5E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #7DCFC3;
    margin-top: 30px;
}

h4.setting-profilepic-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    margin: 0px 96px;
}

.settings button.btn.btn-primary {
    background: rgba(63, 81, 89, 0.2);
    border: 1px solid #5E5E5E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #7DCFC3;
    margin-top: 30px;
    width: 131px;
    height: 50px;
}

.sidenav a img {
    padding-right: 7px;
    margin-bottom: 4px;
}