.offerMade .offerMade {
    background: #293033;
    border-radius: 10px;
    margin: 20px 0px;
    padding: 10px;
}

.offerMade th {
    border: none;
}

.offerMade tr {
    border-left: 1px solid #5D5D5D;
    border-right: 1px solid #5D5D5D;
    border: 1px solid #5D5D5D;

}

.offerMade td.no-data {
    /* color: white; */
    border: none;
    text-align: center;
    color: #828282 !important;
}